"use strict";
//Menu
//Desktop Hover Projekte + Trigger Overlay
const d = document
const images = d.getElementsByClassName("img-container")
const overlays = d.getElementsByClassName("overlay");



//Overlays-Wechsel
for (let i = 0; i < overlays.length; i++) {
    overlays[i].getElementsByClassName("nextProject")[0].addEventListener("click", () => {

        overlays[i].classList.remove("active");
        if (i === (overlays.length - 1)) {
            overlays[0].classList.add("active");
            gallery(overlays[0]);
        } else {
            overlays[i + 1].classList.add("active");
            gallery(overlays[i + 1]);
        }

    });

    overlays[i].getElementsByClassName("prevProject")[0].addEventListener("click", () => {

        overlays[i].classList.remove("active");
        if (i === 0) {
            overlays[overlays.length - 1].classList.add("active");
            gallery(overlays[overlays.length - 1]);
        } else {
            overlays[i - 1].classList.add("active");
            gallery(overlays[i - 1])
        };

    });
    overlays[i].getElementsByClassName("close")[0].addEventListener("click", () => {
        overlays[i].classList.remove("active");
    });

}

//Bei Overlay Wechsel, wird diese Funktion aufgerufen, um die aktuellen Tags zu erhalten
function gallery(overlay) {

    const bigImage = overlay.querySelector(".bigImage img:first-child"),
        animateImage = overlay.querySelector(".bigImage img:last-child");

    let thumbnails = overlay.querySelectorAll(".wrapper .image-holder img");
    console.log("THUMBS: ", thumbnails)
    let thumbs = Array.prototype.slice.call(thumbnails);
    let zwischen,
        animate = false,
        autoslide;

    for (let i = 0; i < thumbs.length; i++) {
        thumbs[i].addEventListener("click", () => {
            //Nur möglich, wenn gerade kein Übergang stattfindet
            if (!animate) {
                slideImage(i);
            }
        });
    }

    autoslide = setInterval(autoSlide, 10000);


    function autoSlide() {
        //Nur bei aktivem Overlay und keiner Animation
        if (!animate && overlay.classList.contains("active")) {
            let index = 0
            slideImage(index);
        }

    }

    function slideImage(i) {
        zwischen = bigImage.getAttribute("src");
        animateImage.setAttribute("src", thumbs[i].getAttribute("src"));

        //Animate
        bigImage.classList.add("animate");
        animate = true;

        //Aktualisieren der Thumbnails, nachdem Animation fertig ist
        setTimeout(() => {
            //Bilder tauschen
            if (bigImage.width === 0) {
                bigImage.setAttribute("src", animateImage.getAttribute("src"));

                bigImage.classList.remove("animate");

                console.log("width == 0");
                animate = false;
                printnewArray(i);
            }
        }, 1050);

    }

    function printnewArray(i) {
        let j = i;
        while (j < thumbs.length - 1) {
            thumbs[j].setAttribute("src", thumbs[(j + 1)].getAttribute("src"));
            j++;
        }
        thumbs[thumbs.length - 1].setAttribute("src", zwischen);
    }


}